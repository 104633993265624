@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500&display=swap");

/* reset styles */
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* additional styles */
body {
  background: #181a20;
  color: #fff;
  font-family: "Rajdhani", sans-serif;
}
h1,
h2,
h3 {
  font-weight: bold;
}
a {
  text-decoration: none;
  color: blue;
}

::placeholder {
  color: #858585;
}

h1 {
  font-size: 30px;
  font-weight: 500;
}

h2 {
  font-size: 24px;
  font-weight: 500;
}

.form__container {
  display: flex;
  flex-direction: column;
  margin: 50px 12%;
  height: 100vh;
}

.form__container__form__heading {
  margin: 30px 0 10px;
}

.form__container__form {
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
}

.form__container__form__input {
  margin: 10px 0;
  padding: 12px 16px;
  border-radius: 6px;
  border: none;
  background: #35373d;
  color: #fff;
  font-family: "rajdhani";
  font-size: 20px;
  flex-grow: 1;
}

.form__container__form__input_Phone {
  margin: 10px 0;
  border-radius: 6px;
  width: 0px;
  padding: 0px 16px;
  border: none;
  background: #35373d;
  color: #fff;
  font-family: "rajdhani";
  font-size: 20px;
  flex-grow: 1;
}

.form__container__form__input_Phone .PhoneInputInput {
  height: 100%;
  background: #35373d;
  color: #fff;
  padding: 0 16px;
}


.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.form__container__form__input_radio {
  display: flex;
  flex-direction: row;
  margin: 30px 0;
}

.row_radio {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.radio_option {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 10px;
  border: 1px solid #35373d;
  border-radius: 6px;
  flex-grow: 1;
  cursor: pointer;
}

.selected {
  border: 1px solid #0c9110;

  background-color: #0c91361c;
}

.para {
  font-size: 14px;
  color: #b4b4b4;
}

.form__container__form__submit {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  background: #054017;
  width: 100%;
}

.form__container__form__submit--content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding: 0 12%;
}

.footerHeading {
  font-size: 20px;
  font-weight: 500;
}
.form__container__form__submit__button {
  display: flex;
  padding: 12px 16px;
  border-radius: 6px;
  border: none;
  background: #0c9110;
  color: #fff;
  font-family: "rajdhani";
  font-size: 20px;
  cursor: pointer;
  max-width: 300px;
  justify-content: center;
  width: 100%;
  transition: 0.2s;
}
.form__container__form__submit__button:hover {
  background: #097a0d;
}

.radio_content {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .form__container {
    margin: 50px 5%;
  }

  .row {
    gap: 10px;
  }

  .form__container__form__submit--content {
    padding: 0 5%;
  }

  .footerHeading {
    font-size: 16px;
  }
}

@media screen and (max-width: 540px) {
  .form__container {
    margin: 50px 5%;
  }
  .row {
    flex-direction: column;
    gap: 0;
  }

  .form__container__form__submit--content {
    flex-direction: column;
    gap: 10px;
  }

  .form__container__form__submit {
    padding: 10px 0;
  }

  .form__container__form {
    margin-bottom: 80px;
  }

  .form__container {
    margin-bottom: 40px;
    height: 100%;
  }

  .form__container__form__input_Phone {
    margin: 10px 0;
    border-radius: 6px;
    width: 91%;
    padding: 0px 16px;
    border: none;
    background: #35373d;
    color: #fff;
    font-family: "rajdhani";
    font-size: 20px;
    flex-grow: 1;
  }
  
  .form__container__form__input_Phone .PhoneInputInput {
    height: 50px;
    background: #35373d;
  }
}
